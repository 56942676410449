
/deep/.el-checkbox{
    position: relative;
    margin:0
}
/deep/.el-checkbox__label{
    padding-left: 0;
}
/deep/.el-checkbox__input{
    position: absolute;
    bottom: -10%;
    left: 50%;
}

.creationCompetitionTwo{
    padding:0 0 60px 30px;
    .creationCompetitionTwo-mid{
        display: flex;
        width:100%;
        margin-top: 24px;
        .templateMain{
        display: flex;
        justify-content: flex-start;
        width:100%;
        height: 30%;
        flex-wrap: wrap;
            .templateItem{
            width: 20%;
            height: 400px;
            
            // position: relative;
            // margin-left: 15px;
            transition: 0.5s;
            display: flex;
            &:hover{
                transform: scale(1.1,1.1);
                cursor: pointer;}
            span{
                margin: 0 auto;
                margin-top: 110%;
                /*position: absolute;*/
                /*bottom: 60px;*/
                /*left: 40%;*/
            }
        }
        }
        .templateItem:nth-child(6),
        .templateItem:nth-child(7) {
            position: relative;
            background-position: 17px 0 !important;
            &::before {
                content: "";
                position: absolute;
                width: 83.5%;
                height: 90%;
                top: 50%;
                left: 5.5%;
                transform: translate(0%, -50%);
                opacity: .3;
                border-radius: 17px;
                box-shadow: 0 0 10px 1.5px #1122d8;
            }
        }
        .templateItem:nth-child(1){
            background: url("./../../../assets/images/competition/dianpuzhuangxiu.png") no-repeat;
        }
        .templateItem:nth-child(2){
            background: url("./../../../assets/images/competition/kehufuwu.png") no-repeat;
        }
        .templateItem:nth-child(3){
            background: url("./../../../assets/images/competition/yunyingtuiguang.png") no-repeat;
        }
        .templateItem:nth-child(4){
            background: url("./../../../assets/images/competition/yunyingfenxi.png") no-repeat;
        }
        .templateItem:nth-child(5){
            background: url("./../../../assets/images/competition/lilunkaoshi.png") no-repeat;
        }
        .templateItem:nth-child(6){
            background: url("./../../../assets/images/competition/Frame427318754.png") no-repeat;
        }
        .templateItem:nth-child(7){
            background: url("./../../../assets/images/competition/Frame427318755.png") no-repeat;
        }
    }
    .upDownBtn{
        margin-top: 120px;
        margin-left: 44%;
    }
}
